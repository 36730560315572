/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css
 * - /npm/swiper@4.5.1/dist/css/swiper.min.css
 * - /npm/malihu-custom-scrollbar-plugin@3.1.5/jquery.mCustomScrollbar.min.css
 * - /npm/flatpickr@4.6.13/dist/flatpickr.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
